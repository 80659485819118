*::selection {
  background: RGB(186, 165, 218);
  color: #fff;
}
html body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: auto;
}
body::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

body::-webkit-scrollbar-thumb {
  background: rgb(137, 142, 151);
}

body::-webkit-scrollbar-track {
  background: #ededed;
}
