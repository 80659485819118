.footer{
    width: 100%;
    height: fit-content;
    align-items: center;
    background: rgb(35, 35, 35);
    font-family: "Helvetica Neue", "Segoe UI", Arial, sans-serif;
    justify-content: center;
    text-align: center;
    padding: 15px 0;
}

.footer div{
    color: RGBA(244, 244, 244, 0.95);
    font-size: 16px;
    letter-spacing: 1px;
}
.footer .about{
    margin: 0 20px;
    font-size: small;
}
.footer .copyright{
    margin: 5px 0;
}

.footer .btn-container{
    margin: 0 auto;
}

.footer .fooBtn{
    margin: 0 20px;
    display: inline-block;
    font-size: large;
    text-decoration: none;
    color: RGBA(244, 244, 244, 0.95);
    cursor: pointer;
    user-select: none;
}
.footer .fooBtn:hover{
    color: RGBA(244, 244, 244, 0.95);
    -webkit-transform: scale(1.1);
    transform: scale(1.01);
}