.overlay::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

.overlay::-webkit-scrollbar-thumb {
  background: rgb(137, 142, 151);
}

.overlay::-webkit-scrollbar-track {
  background: #ededed;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* 设置一个较高的 z-index 值，确保它在其他元素之上 */
  transition: transform 0.6s cubic-bezier(0, 1.16, 1, 1), opacity 0.6s linear;
  overflow: auto;
  background-color: rgb(244 246 255);
}

.return {
  margin-top: 4vh;
  margin-left: 5vw;
  text-align: left;
}

.footer .content div {
  color: rgb(20 20 20);
}

.content {
  margin-bottom: 40px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;

  .body {
    width: max-content;

    .title {
      text-align: center;
      font-size: 20px;
      background-color: rgb(220 225 239);
      user-select: none;
      border-radius: 12px;
    }

    .text {
      margin-top: 20px;
      text-align: left;
    }

    .text,
    .title {
      margin-left: 75px;
      padding: 6px;
    }
  }

  .sidebar {
    position: sticky;
    top: 15px;
    margin-left: 82vw;
    height: 0px;
    text-align: left;

    .explain::before{
      content: " ";
      width: 1px;
      height: 68px;
      background: black;
      position: absolute;
      left: -10px;
  
    }

    span {
      border-bottom: 6px solid rgb(108, 132, 255);
      font-size: 20px;
      box-sizing: content-box;
      cursor: pointer;
    }
  }
}
