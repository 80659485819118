.body-container {
  position: relative;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.body-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.body-title {
  font-size: 30px;
  color: #fff;
  font-weight: bolder;
  text-shadow: 0 0 32px rgba(0, 0, 0, 0.6);
}

.body-button-container Button{
  display: inline-block;
  width: 180px;
  height: 40px;
  margin: 10px;
  font-size: 16px;
  color: #fff;
}

.body-button-container .download-btn{
  background-color: rgb(146,168,255);
}
.body-button-container .support-btn{
  background-color: rgb(255,124,129);
}
.body-button-container .support-btn:hover{
  background-color: rgba(255,124,129,0.75);
  transform:scale(1.02);
  color: #fff;
} 
.body-button-container .download-btn:hover{
  background-color: rgba(146,168,255,0.75);
  transform:scale(1.02);
  color: #fff;
}

.groundPlace{
  margin: 100px 0 0 5%;
  box-shadow: inset 0 0 40px -10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: url(./NewLavenderDesign.jpg) center/cover no-repeat;
  overflow: hidden;
  width: 90vw;
  height: 60vh;
}

@media screen and (max-width: 768px) {
  .body-title {
    font-size: 24px;
  }

  .body-button-container Button {
    margin: 5px;
  }
}

@media screen and (max-width: 480px) {
  .body-title {
    font-size: 20px;
  }

  .body-button-container Button {
    margin: 2px;
  }
}
