.top{
  position: fixed;
  /* min-height: 6vh; */
  height: auto;
  width: 100vw;
  top: 0;
  margin: 0;
  padding: 13px 0;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  box-shadow: rgba(30, 30, 30, 0.2) 0px -5px 30px 0px;
  z-index: 99;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.top .title{
  flex: 1;
  text-align: center;
  text-decoration: none;
  color: rgb(98, 99, 100);
  font-size: 1.1rem;
  cursor: pointer;
  user-select: none;
}

.top .menu{
  flex: 2;
  text-align: center;
}

.top .menu .item{
  text-align: center;
  border: none;
  box-shadow: none;
  font-size: 16px;
  margin: 0 10px;
  background: none;
}
.top .menu :first-child{
  margin-left: 0;
}
.top .menu :last-child{
  margin-right: 0;
}

.top .donate{
  flex: 1;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .top .menu {
    flex: 1.5;
  }

  .top .menu .item {
    font-size: 14px;
    margin: 0;
  }
}
