.intro {
  position: relative;
  margin-top: 80px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.introItem {
  display: inline-block;
  margin-left: 2%;
  margin-right: 2%;
  max-width: 250px;
  cursor: default;
}

.introItem span {
  font-size: 0.82rem;
  color: rgb(72, 75, 73);
}

@media screen and (max-width: 960px) {
  .introItem {
    width: 48%;
    max-width: 48%;
    margin: 1%;
  }
}

@media screen and (max-width: 480px) {
  .introItem {
    width: 90%;
    max-width: 100%;
    margin: 5%;
  }
}
