@import url("https://fonts.loli.net/css2?family=Bodoni+Moda:opsz,wght@6..96,600&family=Noto+Sans+SC:wght@100..900&family=Open+Sans:wght@300;400&display=swap");

.acknowledgement {
    position: fixed;
    bottom: 26px;
    right: 18px;
    height: auto;
    max-height: 220px;
    min-height: 180px;
    width: 350px;
    font-family: "Open Sans";
    padding: 14px 18px;
    box-shadow: 7px 7px 23px rgba(0, 0, 0, 0.24);
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(16px);
    user-select: none; // 禁止用户选择

    .header {
        display: flex;
        justify-content: space-between;

        .title {
            color: #2D2D2D;
            font-size: 18px;
        }

        .closer {
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
            user-select: none;
        }
    }

    .content {
        div {
            margin: 0;
            position: relative;
        }

        padding: 0;

        .names {
            // 容器
            position: relative;
            min-height: 80px; // 最小高度
            max-height: 83px; // 最大高度
            margin-top: 1px; // 顶部边距
            border-top: 1px solid #dcdcdc; // 顶部边框
            padding: 10px 10px 10px 0; // 内边距
            overflow-y: auto; // 垂直滚动
            overflow-y: scroll;

            &::-webkit-scrollbar {
                // Webkit 浏览器（Chrome, Safari, Edge）
                display: none;
            }

            scrollbar-width: none; // Firefox

            -ms-overflow-style: none; // Internet Explorer 和旧版 Edge

            flex-wrap: wrap; // 换行
            gap: 5px;

            span {
                flex: 1 1 calc(20% - 5px); // 每个span的宽度
                box-sizing: border-box;
                text-align: center; // 文字居中
                white-space: nowrap; // 不换行
                overflow: hidden;
                text-overflow: ellipsis; // 超出部分隐藏
                display: inline-block;
            }
        }

        .mask {
            position: absolute;
            z-index: 114514;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
            user-select: none;
            pointer-events: none; // 禁用遮罩的点击事件
        }
    }

    .title {
        font-size: 20pt;
        margin: 5px 0;
    }

    .contributors {
        font-size: 16pt;
        color: #5750D3;
        cursor: pointer;
        position: relative;
        padding-top: 8px;
        padding-bottom: 1px;
        border-bottom: 3px solid;
        width: fit-content;
    }
}